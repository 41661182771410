@font-face {
  font-family: "Lemonmilk";
  src: url("/assets/fonts/lemonmilk/LemonMilkBold-gx2B3.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Lemonmilk";
  src: url("/assets/fonts/lemonmilk/LemonMilkRegular-X3XE2.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lemonmilk";
  src: url("/assets/fonts/lemonmilk/LemonMilkRegularItalic-L3AEy.otf")
    format("opentype");
  font-weight: normal;
  font-style: italic;
}
