@font-face {
  font-family: "Bebas Neue Regular";
  src: url("/assets/fonts/BebasNeue-Regular.woff2") format("woff2"),
    url("/assets/fonts/BebasNeue-Regular.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue Regular";
  src: url("/assets/fonts/BebasNeue-Regular.woff2") format("woff2"),
    url("/assets/fonts/BebasNeue-Regular.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue Regular";
  src: url("/assets/fonts/BebasNeue-Regular.woff2") format("woff2"),
    url("/assets/fonts/BebasNeue-Regular.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue Regular";
  src: url("/assets/fonts/BebasNeue-Regular.woff2") format("woff2"),
    url("/assets/fonts/BebasNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bebas Neue Regular";
  src: url("/assets/fonts/BebasNeue-Regular.woff2") format("woff2"),
    url("/assets/fonts/BebasNeue-Regular.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
