@mixin reset-document-styles() {
  /* https://raw.githubusercontent.com/jgthms/minireset.css/master/minireset.min.css */
  html {
    font-size: 19.5px;
  }
  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    background-color: inherit;
    color: inherit;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }
  ul {
    list-style: none;
  }
  button,
  input,
  select,
  textarea {
    margin: 0;
  }
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  img,
  embed,
  iframe,
  object,
  audio,
  video {
    height: auto;
    max-width: 100%;
  }
  iframe {
    border: 0;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td,
  th {
    padding: 0;
    text-align: left;
  }
}
@include reset-document-styles();

$branding-color: hsl(353, 69%, 65%);    // #e36877
$standard-background-color: #eeeeee;
$visible-in-header-color: black;
$visible-on-branding-color: white;
$standard-vertical-spacing: 0.5rem;
$mobile-vertical-spacing: 2rem;

@mixin identify-section() {
  border: 1px dashed blue;
  margin: 0px;
}

@mixin vertical-padding($how-much-space) {
  padding-top: $how-much-space;
  padding-bottom: $how-much-space;
}

@import "bebas-neue-font.scss";
@import "lemonmilk-font.scss";
@import "inter-font.scss";

$body-text-font-family: "Inter", "Open Sans", sans-serif;
$headline-font-family: "Lemonmilk", "Bebas Neue Regular", "Quando", "Roboto",
  sans-serif;

@mixin typography() {
  & {
    font-family: $body-text-font-family;
    font-size: 1rem;
    font-weight: normal;
    line-height: calc(19.5/12);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $headline-font-family;
  }

  h1 {
    font-size: 200%;
  }
  h2 {
    font-size: 160%;
  }
  h3 {
    font-size: 140%;
  }
  h4 {
    font-size: 120%;
  }
  h5,
  h6 {
    font-size: 110%;
  }
}

// CONTRACT: Mark the two parts as classes part-1 and part-2.
@mixin swap-colors-on-hover($part-1-color, $part-2-color) {
  & {
    .part-1 {
      color: $part-1-color;
    }
    .part-2 {
      color: $part-2-color;
    }
  }

  &:hover {
    .part-1 {
      color: $part-2-color;
    }
    .part-2 {
      color: $part-1-color;
    }
  }
}

@mixin swap-background-and-text-colors-on-hover(
  $background-color,
  $text-color
) {
  background-color: $background-color;
  color: $text-color;

  &:hover {
    background-color: $text-color;
    color: $background-color;
  }
}

@mixin default-paragraph-margins() {
  :last-child {
    margin-bottom: 0;
  }

  p,
  blockquote,
  .highlight,
  .notice,
  details {
    margin-bottom: $standard-vertical-spacing;

    &.testimonial {
      margin-bottom: 0 !important;
    }
  }

  .aside,
  .highlight,
  .notice {
    margin-top: 2 * $standard-vertical-spacing;
    margin-bottom: 2 * $standard-vertical-spacing;
  }
}

@mixin headline-text() {
  font-size: 150%;
}

@mixin stop-link-from-changing-color-on-visit() {
  color: inherit;

  &:visited {
    color: inherit;
  }
}

@mixin sell-online-training-banner() {
  background-color: $branding-color;
  color: $visible-on-branding-color;

  a {
    @include stop-link-from-changing-color-on-visit();
  }

  text-align: center;
  // Provide padding for the reverse color to stand out
  padding: 0.5em;

  @include headline-text();
}

@mixin responsive-embedded-video-styles() {
  .centered-text-flow-around {
    // We need display: block in order to ensure that we can center this item
    display: block;
    text-align: center;
    margin: auto;

    margin-top: 1em;
    margin-bottom: 1em;
  }

  // https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
  .embedded-video-container {
    position: relative;
    height: 0;
    padding-bottom: 65%; // tweaked visually

    .embedded-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .body-text-block {
    max-width: 90%;
    @extend .centered-text-flow-around;
  }
}

@mixin common-front-page-styles() {
  @include default-paragraph-margins();

  .single-column-layout {
    @include main-page-grid-layout();
  }

  .topline {
    @include sell-online-training-banner();
  }

  @include responsive-embedded-video-styles();

  .hero-link {
    font-family: "Bebas Neue Regular";

    a.jbrains-link {
      @include swap-colors-on-hover($visible-in-header-color, $branding-color);
      text-decoration: none;
    }
  }

  .navigation-button {
    @include swap-background-and-text-colors-on-hover(
      $branding-color,
      $visible-on-branding-color
    );
    text-decoration: none;
  }

  .navigation-button {
    .button-headline {
      font-family: $headline-font-family;
      letter-spacing: 0.03em;
    }

    .button-details {
      font-family: $body-text-font-family;
      font-style: italic;
    }
  }

  #shortcuts {
    display: grid;
    grid-template-columns: none;
    grid-gap: 0em;
  }

  .testimonial {
    grid-template-columns: none;
    grid-template-rows: auto;
    grid-gap: 1em;
  }

  #testimonials {
    background-color: lighten($branding-color, 0%);
    color: $visible-on-branding-color;
    a {
      @include stop-link-from-changing-color-on-visit();
    }
  }

  #compelling-statement {
  }

  .call-to-action {
    font-weight: bold;
    font-size: 150%;
  }

  .service-tile {
    background-color: lighten($branding-color, 30%);
  }

  // https://stackoverflow.com/a/20583177/253921
  // Center an iframe within a div.
  .callout {
    iframe {
      display: block;
      width: 100%;
      margin: auto;
    }
    margin-left: auto;
    margin-right: auto;
  }

  .service-tile {
    border: 1px solid;
    padding: 1em;
  }

  .service-content {
  }

  .service-title {
    font-family: $headline-font-family;
  }

  .service-teaser {
    font-style: italic;
  }

  #copyright {
    margin: auto;
  }
}

@mixin common-text-layout() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0.7em;
    margin-bottom: 0.3em;
  }

  $heading-font-size: 250%;
  h1 {
    font-size: $heading-font-size;
  }
  h2 {
    font-size: 0.75 * $heading-font-size;
  }
  h3 {
    font-size: 0.6 * $heading-font-size;
  }

  .page-title {
    margin-top: 1rem !important;
  }

  li {
    margin-bottom: $standard-vertical-spacing;
  }

  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  ol,
  ul {
    margin-left: 2em;
  }

  .related-articles {
    display: flex;
    flex-direction: column;

    list-style-type: none;
    margin-left: 0;
  }

  li.related-article-summary {
  }
}

@mixin main-page-grid-layout() {
  display: grid;
  grid-template-columns: none;
  grid-template-rows: auto;
}

@mixin full-screen-layout-over-hero-image(
  $hero-content-relative-width,
  $full-width-title-link-font-size
) {
  display: grid;
  grid-template-columns: 1fr $hero-content-relative-width;
  grid-template-rows: auto;
  grid-gap: 0.5em;
  grid-template-areas:
    "hero-content navigation-menu"
    "shortcuts shortcuts";

  #shortcuts {
    grid-area: shortcuts;
  }

  .hero-content {
    grid-area: hero-content;

    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 0.5em;

    width: 90%;
    margin: 0 auto 0 auto;
  }

  .navigation-menu {
    align-self: start;
    grid-area: navigation-menu;
  }

  $title-link-font-size: calc($hero-content-relative-width/100%) *
    $full-width-title-link-font-size;
  .hero-link {
    text-align: center;
    font-size: 6vw;
    line-height: 1.3;
  }

  .note {
    margin: 0 auto 0 auto;
    width: 80%;
  }
}

@mixin inset-border($distance-to-border) {
  border: 1px solid black;
  margin: -1 * $distance-to-border;
  padding: $distance-to-border;
}

@mixin avoid-crowding-horizontal-edges() {
  margin-left: 10%;
  margin-right: 10%;
}

@mixin extra-wide-margins() {
  width: 50vw;

  .callout-text {
    margin-left: 20%;
    margin-right: 20%;
  }
}

@mixin common-screen-styles() {
  background-color: $standard-background-color;

  // Whatever happens to the body, center it horizontally
  margin: 0 auto 2rem auto;

  .footer {
    margin: 2rem auto 0 auto;
  }

  ul,
  ol {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  .callout {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .twitter-tweet {
    /* Override the 500px fixed width that Twitter sets by Javascript. Bastards. */
    width: auto !important;
  }

  .highlight,
  .notice,
  .note,
  .special-offer {
    border: 1px solid;
    padding: 0.5em;
  }

  .aside {
    padding: 1em;
    background-color: lighten($branding-color, 30%);
  }

  details[open] {
    border: 1px solid;
    padding: 0.5em;
    background-color: darken($standard-background-color, 10%);

    summary {
      margin-bottom: 1em;
    }
  }

  #primary-navigation-menu {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: auto;
  }

  #primary-navigation-buttons {
    display: grid;
    grid-gap: 0.2em;
  }

  figure.tweet {
    margin-left: auto;
    margin-right: auto;

    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  details.testimonial {
    background-color: lighten($branding-color, 25%);
    border: 1px solid;
    padding: 1em;

    .callout {
      // Eventually, we'll remove the styles from the old front page
      // and we won't need "!important" here
      width: 100% !important;
      max-width: 100% !important;
    }

    summary::before {
      content: url(/images/icons/noun_testimonial_1326785-small.png);
    }
  }

  .public-course-directory {
    display: flex;

    .public-course-poster {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a,
      a:visited {
        color: unset;
      }
    }
  }

  form.standard-form {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    grid-gap: 1em;

    fieldset {
      display: flex;
      flex-flow: column wrap;
      grid-gap: 1em;
    }

    fieldset {
      padding: 1em;
      width: 100%;
      max-width: 50em;
    }

    /* Honeypot fieldset to avoid unwanted submissions */
    fieldset#respond {
      display: none;
    }

    label, label span {
      display: block;
    }

    .checkbox-group {
      display: flex;
      flex-flow: column wrap;
      grid-gap: 0.2em;
    }

    label.labeled-checkbox {
      display: flex;
      flex-flow: row nowrap;
      grid-gap: 0.5em;
      align-items: baseline;

      span {
        display: inline;
      }
    }

    textarea {
      background-color: white;
    }

    textarea {
      font-family: inherit;
      font-size: 100%;
    }

    textarea, input[type="text"], input[type="email"] {
      width: 100%;
    }

    textarea {
      max-width: 40em;
    }

    input[type="text"], input[type="email"] {
      max-width: 30em;
    }

    select {
      max-width: 20em;
    }

    textarea, input {
      padding: 0.5em;
    }

    /* SMELL I'm forced to mark the parent element as "required" in order to style it. */
    label.required {
      & span {
        font-weight: bold;
      }
      /* ...but not the options in labeled checkboxes. */
      .labeled-checkbox span {
        font-weight: initial;
      }
    }

    .instructions {
      font-size: 80%;
    }
  }
}

@mixin request-invoice-styles() {
  #request-invoice-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1em;

    .option {
      text-align: center;

      width: 20em;
      max-width: 80%;

      .icon {
        width: 20vw;
        margin: -5%;
      }

      figcaption {
      }

      button {
        background-color: $branding-color;
        color: #ffffff;
        padding: 0.8em;
        margin: 0.8em;
      }

      border: 1px solid;
      padding: 0.5em;

      // SMELL Remove the visited/unvisited colors and make them match normal text.
    }
  }
}

@mixin narrow-screen-styles() {
  margin: 0 auto 2rem auto;

  .body-text-container {
    $heading-font-size: 180%;
    h1 {
      font-size: $heading-font-size;
    }
    h2 {
      font-size: 0.75 * $heading-font-size;
    }
    h3 {
      font-size: 0.6 * $heading-font-size;
    }
  }

  .page-container {
    grid-gap: $mobile-vertical-spacing;
  }

  #hero-image {
    display: grid;
    grid-gap: $mobile-vertical-spacing;
  }

  .hero-link {
    text-align: center;
    font-size: 400%;
  }

  .navigation-header {
    margin: auto;
    font-size: 200%;
  }

  .public-course-directory {
    flex-direction: column;
    align-items: center;
    grid-gap: 2em;

    .public-course-poster {
      max-width: 100%;

      img {
        width: 600px;
      }
    }
  }

  #succinct-banner {
    margin-bottom: 1rem;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 1em;
    height: auto;

    a {
      margin-left: auto;
      margin-right: auto;
    }

    #logo {
      height: 80px;
      margin-left: auto;
      margin-right: auto;
    }

    // Turn this into a dropdown menu icon?
    #banner-shortcuts {
      display: flex;
      flex-direction: column;
      margin: auto;

      .navigation-header {
        display: flex;
        flex-direction: row;

        font-size: 100%;
      }

      &.collapsed {
        .icon-spacer {
          background-image: url(/images/icons/expand.png);
        }
        .navigation-option {
          // display: inline -> can't change height
          display: block;
          height: 0;
          visibility: hidden;
        }
      }
      &.expanded {
        .icon-spacer {
          background-image: url(/images/icons/collapse.png);
        }
        .navigation-option {
          display: block;
          height: auto;
          visibility: visible;
        }
      }
      #menu-button {
        .icon-spacer {
          $space-for-icon: 3em;
          width: $space-for-icon;
          background-size: $space-for-icon $space-for-icon;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      .navigation-option {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }

  #primary-navigation-buttons {
    grid-template-columns: repeat(auto-fit, 1fr);
  }

  .navigation-button {
    padding: 0.5em;

    .button-headline {
      font-size: 150%;
    }
  }

  #headline {
    display: grid;
    align-items: start;
    grid-gap: $mobile-vertical-spacing;
  }

  #describe-jbrains {
    font-size: 150%;
    font-weight: black;
  }

  // I want a fixed height to avoid content jumping
  // around while someone is reading.
  #testimonials {
    height: 20em;
    overflow-y: scroll;
  }

  .testimonial {
    padding: 1em;
  }

  .service-tile {
    display: grid;
    grid-gap: $mobile-vertical-spacing;
  }

  .service-title {
    font-size: 200%;
  }

  .service-teaser {
    border-bottom: 1px solid;
    font-size: 120%;
  }

  .ieee-software-callout {
    .featured-image {
      margin: auto;
      width: 50%;
    }
  }

  .paragraph-icon,
  .sidenote {
    float: right;
    max-width: 30%;
    border: 0px;
    margin-left: 1em;
    margin-bottom: 2em;
  }

  .sidenote {
    font-size: 80%;
  }

  figure.tweet {
    max-width: 90%;
    width: 600px;
  }

  @include request-invoice-styles();

  .training-course-offering {
    display: flex;
    flex-flow: column nowrap;
    grid-gap: 2em;
  }

  .public-course-poster {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    img {
      width: 300px;
      max-width: 100%;
    }
  }
}

@mixin text-columns() {
  column-width: 14em;
  column-gap: 2em;
}

@mixin wide-screen-styles() {
  .body-text-block {
    max-width: 60%;
  }

  #hero-image {
    background: url(/images/2016-WhitmansDeli-Stockholm.jpg);
    background-size: cover;

    @include full-screen-layout-over-hero-image(50%, 12em);
  }

  .hero-content {
    display: block;
    padding: 0;
  }

  /* Already a grid */
  #primary-navigation-menu {
    grid-template-columns: repeat(auto-fit, minmax(6cm, 1fr));
    grid-template-rows: auto;
  }

  .navigation-header {
    display: none;
  }

  #shortcuts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(8em, 1fr));
    grid-gap: 0.5em;

    font-size: 80%;
  }

  #succinct-banner {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(240px, 1fr) auto;
    grid-template-areas: "logo shortcuts";
    grid-row-gap: 0px;
    grid-column-gap: 1em;

    margin-bottom: 2rem;

    // The logo is too big at screen widths just above mobile
    a {
    }

    #logo {
      height: 80px;
      max-height: 100%;
    }

    #banner-shortcuts {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      margin-right: 0;
      margin-left: auto;

      .navigation-option {
        text-align: baseline;
        font-size: 80%;
        margin-left: 1em;
      }
    }
  }

  .public-course-directory {
    flex-flow: row wrap;
    grid-gap: 2rem;

    .public-course-poster {
      width: 40%;
    }

    img {
      width: 100%;
    }
  }

  #primary-navigation-buttons {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

  .navigation-button {
    @include swap-background-and-text-colors-on-hover(
      $branding-color,
      $visible-on-branding-color
    );
    text-decoration: none;
  }

  .navigation-button {
    padding: 1em;

    .button-headline {
      font-family: $headline-font-family;
      font-size: 120%;
    }

    .button-details {
      font-family: $body-text-font-family;
      font-style: italic;
      font-size: 90%;
    }
  }

  #headline {
    display: grid;
    align-items: start;
    grid-gap: $mobile-vertical-spacing;
    grid-template-columns: 3fr minmax(16rem, 2fr);
    grid-template-areas:
      "describe-jbrains testimonials"
      "compelling-statement testimonials"
      "call-to-action testimonials";
    grid-column-gap: 2em;
    grid-row-gap: 1em;

    #describe-jbrains {
      grid-area: describe-jbrains;
    }
    #compelling-statement {
      grid-area: compelling-statement;
    }
    .call-to-action {
      grid-area: call-to-action;
    }
    #testimonials {
      grid-area: testimonials;
    }
  }

  #describe-jbrains {
    font-family: "HKGroteskBold", $body-text-font-family;
    font-size: 160%;
    font-weight: bold;
  }

  #compelling-statement {
    @include text-columns();
  }

  .testimonial {
    padding: 1em;
  }

  .service-tile {
    display: grid;

    grid-template-columns: 1fr 2fr;
    grid-gap: 2em;
    grid-template-rows: auto;
    grid-template-areas:
      "service-caption service-content"
      ". service-footer";

    .service-caption {
      grid-area: service-caption;
    }

    .service-content {
      grid-area: service-content;
    }

    .block-link {
      grid-area: service-footer;
      justify-self: end;
    }
  }

  .service-tile {
    background-color: lighten($branding-color, 30%);
  }

  .service-content {
  }

  // https://stackoverflow.com/a/20583177/253921
  // Center an iframe within a div.
  .callout-with-iframe {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-items: center;
  }

  .callout-text {
    margin-left: 10%;
    margin-right: 10%;
    @include text-columns();
    column-width: 20em !important;
  }

  .service-content {
    @include text-columns();
  }

  .service-title {
    font-size: 200%;
  }

  .service-teaser {
    font-size: 120%;
  }

  .ieee-software-callout {
    display: grid;
    grid-template-columns: 1fr minmax(140px, 20%);
    grid-template-rows: auto;
    grid-column-gap: 2em;
    align-items: center;

    .featured-image {
      grid-column-start: 2;
    }
  }

  .more-information-page {
    .callout {
      max-width: 60%;

      justify-items: center;
      align-items: center;

      margin-left: auto;
      margin-right: auto;
    }
  }

  .paragraph-icon,
  .sidenote {
    max-width: 20%;
    float: right;
    border: 0px;
    margin-left: 2em;
    margin-bottom: 2em;
  }

  .sidenote {
    font-size: 80%;
  }

  figure.tweet {
    width: 60%;
  }

  .training-course-offering {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    grid-gap: 1em;

    .public-course-poster {
      float: right;
      margin-left: 2em;
      margin-bottom: 2em;
      max-width: 35%;
      width: 400px;

      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      img {
        width: 600px;
        max-width: 100%;
      }
    }

    .training-course-description {
    }

    .training-course-delivery-options {
    }
  }
}

#front-page,
.modern-page {
  @include typography();

  @include common-front-page-styles();
  .body-text-container {
    @include common-text-layout();
  }

  .more-information-page {
    figure.featured-logo {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: center;

      img {
        max-height: 10em;
      }
    }
  }

  div {
    // @include identify-section();
  }

  .page-container {
    grid-gap: $standard-vertical-spacing;
  }

  @include common-screen-styles();

  $wide-screen-starts-at-width: 800px;

  @media screen and (orientation: portrait) {
    width: 94vw;
    font-size: 0.8rem;
  }
  @media screen and (orientation: landscape) and (max-width: $wide-screen-starts-at-width) {
    width: 85vw;
    font-size: 0.9rem;
  }
  @media screen and (orientation: landscape) and (min-width: $wide-screen-starts-at-width) {
    width: 80vw;
    font-size: 0.9rem;
  }
  @media screen and (orientation: landscape) and (min-width: 1.25 * $wide-screen-starts-at-width) {
    width: 72vw;
    font-size: 0.9rem;
  }
  @media screen and (orientation: landscape) and (min-width: 1.5 * $wide-screen-starts-at-width) {
    width: 60vw;
    font-size: 0.9rem;
  }
  @media screen and (orientation: landscape) and (min-width: 2 * $wide-screen-starts-at-width) {
    width: 60vw;
    font-size: 1rem;

    // REFACTOR Get rid of me
    .callout-text {
      margin-left: 20%;
      margin-right: 20%;
    }
  }

  @media screen and (orientation: portrait) {
    @include narrow-screen-styles();
  }
  @media screen and (orientation: landscape) and (max-width: $wide-screen-starts-at-width) {
    @include narrow-screen-styles();
  }
  @media screen and (orientation: landscape) and (min-width: $wide-screen-starts-at-width) {
    @include wide-screen-styles();
  }
  @media screen and (orientation: landscape) and (min-width: 2 * $wide-screen-starts-at-width) {
    @include wide-screen-styles();

    .service-tile {
      grid-template-columns: 1fr 3fr;
    }
  }

  figure.testimonial {
    margin-left: auto; margin-right: auto;
    max-width: 640px;
  }

}
